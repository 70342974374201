
    import TablePerformanceMapRoom from "@/components/tables/table-performance-map-room.vue";
    import TableSettings from "@/components/tables/table-settings.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            TablePerformanceMapRoom,
            TableSettings,
        },
    })

    // *****************************************************************************************************************
    export default class DevUnity extends Vue {
        private readonly shared = {
            // tslint:disable
            headers: [
                { text: '#',                    value: 'session',       sortable:   true },
                { text: 'Grdn T',               value: 'GDT',           sortable:   false },
                { text: 'App T',                value: 'APPT',          sortable:   false },

                { text: 'FPS',                  value: 'FPS',           sortable:   false },

                { text: 'Fov Lvl',              value: 'FOV',           sortable:   false },

                { text: 'CPU Lvl',              value: 'CPUL',          sortable:   false },

                { text: 'GPU Lvl',              value: 'GPUL',          sortable:   false },
                { text: 'CPU Use',              value: 'CPUU',          sortable:   false },
                { text: 'GPU Use',              value: 'GPUU',          sortable:   false },

                { text: 'UI',                   value: 'mainMenuIcon',  sortable:   false },

                { text: 'Notes',                value: 'notes',         sortable:   false },
            ],
            // tslint:enable
        };

        private readonly session31122022 = {
            // tslint:disable
            scene: [
                {
                    session:        '1st',

                    GDT:            '0',
                    APPT:           '8700',

                    FPS:            '45|90',

                    FOV:            '0',
                    CPUL:           '0',
                    GPUL:           '3',
                    CPUU:           '34%',
                    GPUU:           '63%',

                    mainMenuIcon:   'not_interested',

                    notes:          'Hexabody Player Controller' 
                },
                {
                    session:        '2nd',

                    GDT:            '0',
                    APPT:           '8700',

                    FPS:            '45|90',

                    FOV:            '0',
                    CPUL:           '0',
                    GPUL:           '3',
                    CPUU:           '42%',
                    GPUU:           '64%',

                    mainMenuIcon:   'not_interested',

                    notes:          'Updated the Oculus Integration to v47' 
                }
            ],
            // tslint:enable
        };

        private readonly session04122022 = {
            // tslint:disable
            scene: [
                {
                    session:        '1st',

                    GDT:            '0',
                    APPT:           '8520',

                    FPS:            '45|90',

                    FOV:            '0',
                    CPUL:           '0',
                    GPUL:           '3',
                    CPUU:           '34%',
                    GPUU:           '61%',

                    mainMenuIcon:   'not_interested',

                    notes:          'Application Spacewarp - not on sky' 
                },
                {
                    session:        '2nd',

                    GDT:            '0',
                    APPT:           '8520',

                    FPS:            '45|90',

                    FOV:            '0',
                    CPUL:           '0',
                    GPUL:           '3',
                    CPUU:           '34%',
                    GPUU:           '64%',

                    mainMenuIcon:   'not_interested',

                    notes:          'ASW on sky too' 
                }
            ],
            // tslint:enable
        };

        private readonly session28112022 = {
            // tslint:disable
            scene: [
                {
                    session:        '1st',

                    GDT:            '0',
                    APPT:           '6622',

                    FPS:            '90',

                    FOV:            '0',
                    CPUL:           '0',
                    GPUL:           '3',
                    CPUU:           '?',
                    GPUU:           '74%',

                    mainMenuIcon:   'not_interested',

                    notes:          'Shaders refactored' 
                },
                {
                    session:        '2nd',

                    GDT:            '0',
                    APPT:           '6981',

                    FPS:            '90',

                    FOV:            '0',
                    CPUL:           '0',
                    GPUL:           '3',
                    CPUU:           '43%',
                    GPUU:           '77%',

                    mainMenuIcon:   'not_interested',

                    notes:          'Oculus Integration v46' 
                },
                {
                    session:        '3rd',

                    GDT:            '0',
                    APPT:           '7820',

                    FPS:            '90',

                    FOV:            '0',
                    CPUL:           '0',
                    GPUL:           '3',
                    CPUU:           '50%',
                    GPUU:           '84%',

                    mainMenuIcon:   'not_interested',

                    notes:          'Switched to Vulkan instead of GLES' 
                }
            ],
            // tslint:enable
        };

        private readonly session04092022 = {
            // tslint:disable
            scene: [
                {
                    session:        '1st',

                    GDT:            '0',
                    APPT:           '6370',

                    FPS:            '90',

                    CPUL:           '0',
                    FOV:            '0',
                    GPUL:           '3',
                    GPUU:           '73%',

                    mainMenuIcon:   'not_interested',

                    ssaa:           '1.255',
                    msaa:           '4',
                    phaseSyncIcon:  'not_interested',

                    notes:          'More photosphere stands' 
                },
                {
                    session:        '2nd',

                    GDT:            '0',
                    APPT:           '6380',

                    FPS:            '90',

                    CPUL:           '0',
                    FOV:            '0',
                    GPUL:           '3',
                    GPUU:           '73%',

                    mainMenuIcon:   'not_interested',

                    ssaa:           '1.255',
                    msaa:           '4',
                    phaseSyncIcon:  'not_interested',

                    notes:          'Unity 2021.3.9f1' 
                }
            ],
            // tslint:enable
        };

        private readonly session19062022 = {
            // tslint:disable
            scene: [                
                {
                    session:        '1st',

                    GDT:            '0',
                    APPT:           '5755',

                    FPS:            '90',

                    CPUL:           '0',
                    FOV:            '0',
                    GPUL:           '3',
                    GPUU:           '69%',

                    mainMenuIcon:   'not_interested',

                    ssaa:           '1.255',
                    msaa:           '4',
                    phaseSyncIcon:  'not_interested',

                    notes:          'Player at (2.17827f, 0f, 3.822685f)' 
                },
                {
                    session:        '2nd',

                    GDT:            '0',
                    APPT:           '6300',

                    FPS:            '90',

                    CPUL:           '0',
                    FOV:            '0',
                    GPUL:           '3',
                    GPUU:           '73%',

                    mainMenuIcon:   'not_interested',

                    ssaa:           '1.255',
                    msaa:           '4',
                    phaseSyncIcon:  'not_interested',

                    notes:          'Player at (0f, 1.6f, 19.5f)' 
                },
                {
                    session:        '3rd',

                    GDT:            '0',
                    APPT:           '',

                    FPS:            '90',

                    CPUL:           '0',
                    FOV:            '0',
                    GPUL:           '',
                    GPUU:           '73%',

                    mainMenuIcon:   'not_interested',

                    ssaa:           '1.255',
                    msaa:           '4',
                    phaseSyncIcon:  'not_interested',

                    notes:          'Oculus Integration v40' 
                },
                {
                    session:        '4th',

                    GDT:            '0',
                    APPT:           '6300',

                    FPS:            '90',

                    CPUL:           '0',
                    FOV:            '0',
                    GPUL:           '3',
                    GPUU:           '73%',

                    mainMenuIcon:   'not_interested',

                    ssaa:           '1.255',
                    msaa:           '4',
                    phaseSyncIcon:  'not_interested',

                    notes:          'Unity 2021.3.4f1' 
                }
            ],
            // tslint:enable
        };

        // Data function
        private data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),

                mapRoomImage01PathAndName:
                    require("@/assets/images/map-room-01.png"),
                mapRoomImage02PathAndName:
                    require("@/assets/images/map-room-02.png"),
                mapRoomImage03PathAndName:
                    require("@/assets/images/map-room-03.png"),
                mapRoomImage04PathAndName:
                    require("@/assets/images/map-room-04.png"),
                mapRoomImage05PathAndName:
                    require("@/assets/images/map-room-05.png"),
                mapRoomImage06PathAndName:
                    require("@/assets/images/map-room-06.png"),
                mapRoomImage07PathAndName:
                    require("@/assets/images/map-room-07.png"),
                mapRoomImage08PathAndName:
                    require("@/assets/images/map-room-08.png"),
                mapRoomImage09PathAndName:
                    require("@/assets/images/map-room-09.png"),
                mapRoomImage10PathAndName:
                    require("@/assets/images/map-room-10.png"),
                mapRoomImage11PathAndName:
                    require("@/assets/images/map-room-11.png"),
                mapRoomImage12PathAndName:
                    require("@/assets/images/map-room-12.png"),
                mapRoomImage13PathAndName:
                    require("@/assets/images/map-room-13.png"),
                mapRoomImage14PathAndName:
                    require("@/assets/images/map-room-14.png"),
                mapRoomImage15PathAndName:
                    require("@/assets/images/map-room-15.png"),
                mapRoomImage16PathAndName:
                    require("@/assets/images/map-room-16.png"),
                mapRoomImage17PathAndName:
                    require("@/assets/images/map-room-17.png"),
                mapRoomImage18PathAndName:
                    require("@/assets/images/map-room-18.png"),
                mapRoomImage19PathAndName:
                    require("@/assets/images/map-room-19.png"),
                mapRoomImage20PathAndName:
                    require("@/assets/images/map-room-20.png"),

                unityIconPathAndName:
                    require("@/assets/images/unity.png"),
            };
        }
    }
